/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tongyu's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Tongyu Hu",
    type: "website",
    url: "tongyuhu.com/",
  },
};

//Home Page
const greeting = {
  title: "Tongyu Hu",
  logo_name: "Tongyu Hu",
  nickname: "Law/Comp Sci",
  subTitle:
    "<if happy: then success>",
  resumeLink:
    "",
  portfolio_repository: "https://github.com/tongyuuu",
  githubProfile: "https://github.com/tongyuuu",
};

const socialMediaLinks = [

  {
    name: "Github",
    link: "https://github.com/tongyuuu",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tongyu-hu-41b65b24a/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:thvisions@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/Flashboom132/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/tongyu._.hu/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Law Student",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Taken courses in Torts, Criminal and Civil Procedure, Criminal Law, Contracts, Public International Law, Torts and Contracts II and Public Law",
        "⚡ Experience in working under a Barrister (John Degreenlaw), in a boutique firm (Axletree Law) as well as in-house legal counsel (Australian Securities Exchange)",
        "⚡ Professional use of Microsoft Word, Excel and Powerpoint, Westlaw and LexisNexis",
      ],
      softwareSkills: [
      ],
    },
    {
      title: "Computer Science Student",
      fileName: "FullStackImg",
      skills: [
        "⚡ Taken courses in Python, Systems Programming, Java, Data Structures and Alogrithms, Artificial Intelligence, Databases, Algorithms",
        "⚡ Experience in Java, Python, C, CSS, TypeScript, React and also Unity",
        "⚡ Work experience as an intern at Atlassian and 1st Place in SYNCSHACK 2024 out of 250+ Competitors",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "unity",
          fontAwesomeClassname: "simple-icons:unity",
          style: {
            color: "#663399",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/tongyu_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@tongyu391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "The University of Sydney",
      subtitle: "Bachelor of Laws / Bachelor of Science (Majoring in Computer Science)",
      logo_path: "usyd_logo.png",
      alt_name: "USYD",
      duration: "2022 - Present",
      descriptions: [
        "➼ WAM: Distinction",
        "➼ Computer Science Courses: ELEC1006, INFO1110, INFO1113, COMP2123, COMP2017, COMP2022, COMP3027, OLET2610, COMP3308, COMP3888, ISYS2120",
        "➼ Law Courses: LAWS1006, LAWS1012, LAWS1014, LAWS1015, LAWS1023, LAWS1017, LAWS1021",
        "➼ Awards/Scholarships: Dalyell Scholar, Global Mobility Scholarship",
      ],
      website_link: "https://www.sydney.edu.au/",
    },
    {
      title: "North Sydney Boys High School",
      subtitle: "High School Certificate",
      logo_path: "nsbhs.png",
      alt_name: "NSBHS",
      duration: "2016 - 2021",
      descriptions: [
        "➼ ATAR: 99.60",
        "➼ Mathematics Advanced/Extension 1/Extension 2, English Advanced, Chemistry, Software Design",
        "➼ Awards/Scholarships: Distinguished Achiever and All Rounder",
      ],
      website_link: "https://northsydbo-h.schools.nsw.gov.au/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Ahead at Ashurst",
      subtitle: "Ashurst",
      logo_path: "ashurst.png",
      certificate_link:
        "https://www.ashurst.com/",
      alt_name: "ashurst",
      color_code: "#8C151599",
    },
    {
      title: "Digital Law: Virtual Internship",
      subtitle: "Herbert Smith Freehills",
      logo_path: "hsf.webp",
      certificate_link:
        "https://forage-uploads-prod.s3.amazonaws.com/completion-certificates/Herbert%20Smith%20Freehills/tkyHgkGXivwRekau5_Herbert%20Smith%20Freehills_kn8r4jqmJeeNAuRga_1673756482662_completion_certificate.pdf",
      alt_name: "freehills",
      color_code: "#ECECEC",
    },
    {
      title: "CU Up Close",
      subtitle: "Clayton Utz",
      logo_path: "clayton.png",
      certificate_link:
        "https://www.claytonutz.com/",
      alt_name: "Clayton",
      color_code: "#0C9D5899",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internships and Societies\n",
  description:
    "I have had a vast range of experience across both my University life and my Professional life, resulting in a vast variety of knowledge in both soft/hard skills. Currently, I am working at the ASX as a Paralegal, with previous experience as a Software Engineering Intern at Atlassian",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Paralegal",
          company: "Australian Securities Exchange",
          company_url: "https://www.asx.com.au/",
          logo_path: "asx.png",
          duration: "March 2024 - Present",
          location: "Sydney, NSW",
          description:
            "Responsibilities included legal research into the Corporations Act, ASX Settlement Operating Rules, ASX Listing Rules and Austraclear Regulations. Responding to search requests for CHESS Holdings, creation of a new Matter List for new in-house matter management system which involved use of Excel and PowerAutomate, generating analytics for the Deputy General Counsel",
          color: "#0879bf",
        },
        {
          title: "Paralegal",
          company: "Ada Evans Chambers",
          company_url: "https://www.adaevanschambers.com",
          logo_path: "ada.png",
          duration: "February 2023 - May 2023",
          location: "Sydney, NSW",
          description:
            "Worked under Barrister John Degreenlaw in aiding his legal matters, involving WID (Work Injury Damages), MVA (Motor Vehicle Accidents), HSAC (Historical Sexual Abuse Cases) by drafting Statements of Claim, writing Chronologies and performing Legal Research.",
          color: "#9b1578",
        },
        {
          title: "Paralegal",
          company: "Axletree Law",
          company_url: "https://axletreelaw.com/",
          logo_path: "axletree.png",
          duration: "August 2022 - May 2023",
          location: "Surry Hills, NSW",
          description:
            "Helped in creation of company website alongside legal research tasks. Further aided in drafting of contracts, research into smart contracts and how to secure web browsers themselves.",
          color: "#fc1f20",
        },
        {
          title: "Academic Tutor",
          company: "Reach Academy",
          company_url: "https://reachacademy.com.au/",
          logo_path: "reach.png",
          duration: "April 2022 - February 2023",
          location: "Castle Hill, NSW",
          description:
            "Tutored students in both private 1-1 sessions as well as in groups for a wide variety of subjects (Maths, English, Chemistry, Software) making sure that they understood the content. Further provided personalised feedback and marking as well as individualised questions for them to excel in their exams.",
          color: "#fc1f20",
        }
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Software Engineering Intern",
          company: "Atlassian",
          company_url: "https://www.atlassian.com/",
          logo_path: "atlassianv2.png",
          duration: "November 2023 - February 2024",
          location: "Sydney, NSW",
          description:
            "Joined the CENG (Cloud Engineering Team) while utilising TypeScript/React with Atlaskit to build and AWS Account Request Form, learning testing frameworks via Jest to complete a whole software development cycle while also learning AWS Tools such as s3 and EC2 Instances",
          color: "#000000",
        },
      ],
    },
    {
      title: "Societies",
      experiences: [
        {
          title: "Engagement Director",
          company: "BusinessOne Consulting University of Sydney",
          company_url: "https://www.microsoft.com/",
          logo_path: "businessOne.png",
          duration: "March 2023 - Present",
          location: "University of Sydney",
          description:
            "Semester 1 2023: Consultant for Bloom Impact Investing, Semester 2 2023: Team Leader for Archistar AI, Semester 1 2024: Internals Director, Semester 2 2024: Engagement Director. Within these roles, I led a team of consultants to work on market entry projects as well as enhancing the UX/UI of a company's system interface.",
          color: "#D83B01",
        },
        {
          title: "Vice President",
          company: "University of Sydney Chocolate Society",
          company_url: "https://au.linkedin.com/company/university-of-sydney-chocolate-society",
          logo_path: "chocsoc.png",
          duration: "September 2022 - October 2023",
          location: "University of Sydney",
          description:
            "Managing the society and assisting with the organisation of weekly events which ranged from trivia nights to distribution of various foods such as blackstar pastry. Further had a role in securing external partnerships, which involved contacting Strike Bowling, Sharetea, etc...",
          color: "#4285F4",
        },
        {
          title: "Vice President of Sponsorships",
          company: "Sydney University Impact Investing Society",
          company_url: "https://www.suiis.com.au/",
          logo_path: "suiis.png",
          duration: "September 2023 - Present",
          location: "University of Sydney",
          description:
            "Securing and managing partnerships and sponsorships, where I identified potential sponsors, negotiated sponsorship deals and maintained relationships with existing sponsors to support SUIIS's mission of promoting impact investing among university students",
          color: "#000000",
        },
        {
          title: "Treasurer",
          company: "USYD Comm-Stem Society",
          company_url: "https://www.usydcommstem.org/",
          logo_path: "commstem.png",
          duration: "April 2024 - Present",
          location: "University of Sydney",
          description:
            "Assisting with various tasks in running events (dealing with catering, ushering attendees into event, helping with signups/information) that included career insights, networking opportunities and more. Some notable events included Introduction to AI and Industry networking nights. Further managed the societies funds and provided reinbusements.",
          color: "#181717",
        },
        {
          title: "Science and Law Mentor",
          company: "University of Sydney",
          company_url:
            "https://www.sydney.edu.au/",
          logo_path: "usyd.png",
          duration: "January 2023 - Present",
          location: "Sydney, Australia",
          description:
            "Mentored two groups of first year Science and Law students, leading weekly sessions which involved disseminating information about how to navigate the first semester of univeristy. Further provided advice on study habits and also societies to join to make the most of University life",
          color: "#0C9D58",
        },
        {
          title: "Industry Subcommittee",
          company: "Sydney University Computing Society",
          company_url: "https://syncs.org.au/",
          logo_path: "syncs.png",
          duration: "March 2023 - September 2023",
          location: "University of Sydney",
          description:
            "As an industry liaison, I was responsible for contacting a wide variety of sponsors/partners, bringing on new sponsors as well as liaising with them to organise company participation at student run events at the Univeristy. Some notable events included site tours at Atlassian, Quantium and FDM Group",
          color: "#181717",
        },
        {
          title: "Careers Guide Editor",
          company: "Sydney University Law Society",
          company_url: "https://www.suls.org.au/",
          logo_path: "suls.png",
          duration: "January 2023 - March 2023",
          location: "University of Sydney",
          description:
            "Worked on the careers guide for 2023, contacting various international law firms to update their relevant brochures and information about their firm. Furthermore summarised and analysed different law firms to create a handbook of information for students to read and understand future career opportunities.",
          color: "#181717",
        },
        {
          title: "Emergency Services Volunteer",
          company: "Australian Red Cross",
          company_url: "https://www.redcross.org.au/",
          logo_path: "red_cross.png",
          duration: "December 2022 - November 2023",
          location: "Sydney NSW",
          description:
            "Worked as an emergency services volunteer where I helped to provide Psychological First Aid to victims of natural disasters. Further attended and helped run safety talks at relevant community centres to promote awareness and safety amongst the community.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My Projects encompass a wide range of languages and skillsets, ranging from 3D Multiplayer games to the creation of a Virtual Machine in the RISKXVIII Architecture. These have allowed me to excel in quickly learning new languages as well as building core fundamental coding skills. See the below for the projects that I have completed.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "SULS 2023",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "headertongyu3.png",
    description:
      "Please see links below for ways to view my content or to contact me :)",
  },
  blogSection: {
    title: "Linkedin",
    subtitle:
      "Click here to view my linkedin",
    link: "https://www.linkedin.com/in/tongyu-hu-41b65b24a/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Sydney NSW, 2000, Australia",
    locality: "Sydney",
    country: "Australia",
    region: "NSW",
    postalCode: "2153",
    streetAddress: "Sydney",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Sydney+NSW/data=!4m2!3m1!1s0x6b129838f39a743f:0x3017d681632a850?sa=X&ved=1t:242&ictx=111",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
